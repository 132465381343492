<template>
    <div>
      <div class="mx-auto px-6 sm:px-6 py-4">
        <div class="py-4">
          <div class="grid grid-cols-1 sm:grid-cols-2 py-4">
            <div>
              <h2 class="text-2xl font-semibold leading-tight">{{titlePage}}</h2>
            </div>
            <div class="text-left py-4 sm:py-0 sm:text-right">
              <button class="save" @click="validateBeforeSubmit">
                <i class="fa fa-save"></i>
                Save
              </button>
              <button class="save" @click="$router.push({name: 'Company',params: {pageno:pageno,perpage: perPage,order:currentSortRoute} })">
                <i class="fa fa-undo"></i>
                Cancel
              </button>
            </div>
          </div>
          <div class="shadow-md">
            <form @submit.prevent="validateBeforeSubmit" class="form" id="frm">
              <div class="bg-gray-100 p-4 border-t-2 bg-opacity-5 border-gray-400 rounded-t"></div>
              <div class="bg-white space-y-6">
                <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col my-2">
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="owner">
                        Company Owner *
                      </label>
                      <div class="relative">
                        <select name="owner" id="owner" v-model="owner" v-validate="'required'" :class="{'block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded':true,'border-red-600': errors.has('owner') }">
                          <option value=""></option>
                          <option v-for="item in owners" :value="item.owner">{{item.label}}</option>
                        </select>
                        <div class="pointer-events-none absolute right-1 top-4 flex items-center px-2 text-grey-darker">
                          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                      </div>
                      <p class="text-red-600 text-xs italic" v-show="errors.has('owner')">{{errors.first('owner')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <div class="-mx-3 md:flex mb-6">
                        <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                          <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="code">
                            Company Code *
                          </label>
                          <input name="code" v-model="code" v-validate="'required'" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('code') }" id="code" type="text" placeholder="">
                          <p class="text-red-600 text-xs italic" v-show="errors.has('code')">{{errors.first('code')}}</p>
                        </div>
                        <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                          <button class="save mt-6" v-if="code==''" @click="generaCodice" type="button">
                            Generate
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="uic">
                        Company UIC *
                      </label>
                      <input name="uic" v-model="uic" v-validate="'required'" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('uic') }" id="uic" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('uic')">{{errors.first('uic')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="name">
                        Company Name *
                      </label>
                      <input name="name" v-model="name" v-validate="'required'" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('name') }" id="name" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('name')">{{errors.first('name')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="cf">
                        Company ID-NUMBER (Cod. Fisc.)
                      </label>
                      <input name="uic" v-model="cf" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('cf') }" id="cf" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('cf')">{{errors.first('cf')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="iva">
                        Company VAT
                      </label>
                      <input name="name" v-model="iva" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('iva') }" id="iva" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('iva')">{{errors.first('iva')}}</p>
                    </div>
                  </div>
                  <div class="pb-4">
                    <h4 class="font-semibold">Details</h4>
                    <hr>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="w-full px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="address">
                        Address
                      </label>
                      <input name="address" v-model="address" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('address') }" id="address" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('address')">{{errors.first('address')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="cap">
                        Cap
                      </label>
                      <input name="cap" v-model="cap" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('cap') }" id="cap" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('cap')">{{errors.first('cap')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="city">
                        City
                      </label>
                      <input name="city" v-model="city" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('city') }" id="city" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('city')">{{errors.first('city')}}</p>
                    </div>
                  </div>
                  <div class="-mx-3 md:flex mb-6">
                    <div class="md:w-1/2 px-3 mb-6 md:mb-0">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="state">
                        State
                      </label>
                      <input name="state" v-model="state" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('state') }" id="state" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('state')">{{errors.first('state')}}</p>
                    </div>
                    <div class="md:w-1/2 px-3">
                      <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="notes">
                        Notes
                      </label>
                      <input name="notes" v-model="notes" :class="{'appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4': true, 'border-red-600': errors.has('notes') }" id="notes" type="text" placeholder="">
                      <p class="text-red-600 text-xs italic" v-show="errors.has('notes')">{{errors.first('notes')}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-2 py-4">
            <div>

            </div>
            <div class="text-left py-4 sm:py-0 sm:text-right">
              <button class="save" @click="validateBeforeSubmit">
                <i class="fa fa-save"></i>
                Save
              </button>
              <button class="save" @click="$router.push({name: 'Company',params: {pageno:pageno,perpage: perPage,order:currentSortRoute} })">
                <i class="fa fa-undo"></i>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import qs from 'qs';
import { AUTH_URL } from "actions/auth";
import { Validator } from 'vee-validate';

const dict = {
  custom: {
    owner: {
      required: 'Your company owner is empty'
    },
    code: {
      required: () => 'Your company code is empty'
    },
    uic: {
      required: 'Your company uic is empty'
    },
    name: {
      required: 'Your company name is empty'
    }
  }
};

Validator.localize('en', dict);

const methods = {
  validateBeforeSubmit(e){
    this.$router.push({name: 'Companies',params: {pageno:this.pageno,perpage: this.perPage,order:this.currentSortRoute} })
    /*this.$validator.validateAll().then((result) => {
      if (result) {
          let myForm = document.getElementById('frm');
          let formData = new FormData(myForm);
          const data = {};
          for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
          }

          if(this.$route.params.id!='' && this.$route.params.id!=undefined){
            var metodo = 'PUT';
            Object.assign(data, {id: this.$route.params.id})
          }else{
            var metodo = 'POST';
          }
          axios({url: AUTH_URL+'master/api/v2/company', data: qs.stringify(data), method: metodo ,
            headers: {
              'Authorization': 'Bearer '+localStorage.getItem("user-token"),
              'Accept' : '*\/*',
              'Access-Control-Allow-Origin': '*',
              'Content-Type': 'application/json'
              }
            })
            .then(resp => {
              //console.log(resp.data)
            })
            .catch(err => {
              this.error = true;
              console.error(err)
          })
          this.$router.push("/companies");
        return;
      }
    });*/
  },
  generaCodice(){
    axios({url: AUTH_URL+'master/api/v2/gen_company_code', method: 'GET' , 
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.code = resp.data.new_company_code
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
  }
};

export default {
  name: 'companiesDetail',
  data() {
    return {
      titlePage: 'New Company',
      currentId: 0,
      detail: [],
      owner: '',
      code: '',
      uic: '',
      name: '',
      address: '',
      cap: '',
      city: '',
      state: '',
      notes: '',
      iva: '',
      cf:'',
      owners: [],
      pageno: this.$route.params.pageno,
      perPage: this.$route.params.perpage,
      currentSortRoute: this.$route.params.order,
    };
  },
  components:{
    Validator
  },
  methods,
  mounted: function mounted(){

  },
  beforeMount: function beforeMount(){
    if(this.$route.params.id!=undefined){
      console.log(this.$route.params)
      this.titlePage = 'Edit Company';
      this.currentId = this.$route.params.id;
      this.detail = this.$route.params.dati;
      this.owner = this.$route.params.dati.owner;
      this.code = this.$route.params.dati.code;
      this.uic = this.$route.params.dati.uic;
      this.iva = this.$route.params.dati.piva;
      this.cf = this.$route.params.dati.cf;
      this.name = this.$route.params.dati.name;
      this.address = this.$route.params.dati.details.address;
      this.cap = this.$route.params.dati.details.cap;
      this.city = this.$route.params.dati.details.city;
      this.state = this.$route.params.dati.details.state;
      this.notes = this.$route.params.dati.details.notes;
    }
    axios({url: AUTH_URL+'master/api/v2/machine_groups', method: 'GET' , 
      headers: { 
        'Authorization': 'Bearer '+localStorage.getItem("user-token"),
        'Accept' : '*/*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
        }
      })
      .then(resp => {
        this.owners = resp.data
      })
      .catch(err => {
        this.error = true;
        console.error(err)
    })
  }
}
</script>
